import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../api";
import projectImg from "../../assets/img/project/pro-4.jpg";
import PropTypes from "prop-types";

export const CourseCard = ({ course = {} }) => {
  const { category = {}, image, coursePrice = "" } = course;
  const navigate = useNavigate();
  const onContainerClick = () => {
    navigate(`/courses/${course.id}`);
  };
  return (
    <div className="project-2__item" onClick={onContainerClick}>
      <div className="project-2__thumb">
        <img src={image ? `${baseURL}${image?.url}` : projectImg} alt="" />

        <div className="project-2__img-text">
          <h4>{course.title}</h4>
          <i>{category?.name}</i> <i> / </i> <i>Rs {coursePrice} </i>
        </div>
      </div>
    </div>
  );
};
CourseCard.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    courseLevel: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired,
    image: {
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    },
  }),
};
