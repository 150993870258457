import api from "../api";
import { getError } from "../utils";
import useDataContext from "./useDataContext";
const main = "main-context";
const mainActionTypes = {
  setLoading: `${main}/setLoading`,
  setError: `${main}/setError`,
  setHomePageData: `${main}/setHomePageData`,
  setCategoriesData: `${main}/setCategoriesData`,
  setCategoriesLoading: `${main}/setCategoriesLoading`,
  setCategoriesError: `${main}/setCategoriesError`,
  setCoursesData: `${main}/setCoursesData`,
  setCoursesLoading: `${main}/setCoursesLoading`,
  setCoursesError: `${main}/setCoursesError`,
};

const INIT_STATE = {
  loading: true,
  error: "",
  homePageData: {},
  categories: [],
  categoriesLoading: true,
  categoriesError: "",
  courses: [],
  coursesLoading: true,
  coursesError: "",
};

const reducer = (state, action = {}) => {
  switch (action.type) {
    case mainActionTypes.setLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case mainActionTypes.setError:
      return {
        ...state,
        error: action.payload,
      };
    case mainActionTypes.setHomePageData:
      return {
        ...state,
        homePageData: action.payload,
      };
    case mainActionTypes.setCategoriesData:
      return {
        ...state,
        categories: [...state.categories, ...action.payload],
      };
    case mainActionTypes.setCategoriesLoading:
      return {
        ...state,
        categoriesLoading: action.payload,
      };
    case mainActionTypes.setCategoriesError:
      return {
        ...state,
        categoriesError: action.payload,
      };
    case mainActionTypes.setCoursesData:
      return {
        ...state,
        courses: action.payload,
      };
    case mainActionTypes.setCoursesLoading:
      return {
        ...state,
        coursesLoading: action.payload,
      };
    case mainActionTypes.setCoursesError:
      return {
        ...state,
        coursesError: action.payload,
      };
    default:
      return state;
  }
};

// Actions
const getHomePage = (dispatch) => {
  return async () => {
    try {
      const response = await api.get("/home-page?populate=*");
      const payload = transformHomePage(response.data);
      dispatch({
        type: mainActionTypes.setHomePageData,
        payload,
      });
    } catch (err) {
      const message = getError(err);
      console.log("GetHomePage", message);
      dispatch({ type: mainActionTypes.setError, payload: message });
    } finally {
      dispatch({ type: mainActionTypes.setLoading, payload: false });
    }
  };
};
const getCategories = (dispatch) => {
  return async () => {
    try {
      const response = await api.get("/categories");
      const payload = transformCategory(response.data);

      dispatch({
        type: mainActionTypes.setCategoriesData,
        payload,
      });
    } catch (err) {
      const message = getError(err);
      console.log("GetCategories", message);

      dispatch({ type: mainActionTypes.setCoursesError, payload: message });
    } finally {
      dispatch({ type: mainActionTypes.setCategoriesLoading, payload: false });
    }
  };
};
const getCourses = (dispatch) => {
  return async () => {
    try {
      const response = await api.get("/courses", {
        params: {
          populate: ["category", "image"],
          fields: ["title", "courseLevel", "coursePrice", "startsFrom"],
          pagination: {
            pageSize: 10,
            page: 1,
          },
          sort: ["createdAt:desc"],
        },
      });
      const payload = transformCourse(response.data);
      dispatch({
        type: mainActionTypes.setCoursesData,
        payload,
      });
    } catch (err) {
      const message = getError(err);
      console.log("GetCourses", message);

      dispatch({ type: mainActionTypes.setCoursesError, payload: message });
    } finally {
      dispatch({ type: mainActionTypes.setCoursesLoading, payload: false });
    }
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const [MainContext, MainProvider] = useDataContext(INIT_STATE, reducer, {
  getHomePage,
  getCategories,
  getCourses,
});

// Transformers

const transformHomePage = (data = {}) => {
  const {
    mainHeading = "",
    mainDescription = "",
    aboutTitle = "",
    aboutDescription = "",
    linkedinPageLink = "#",
    twitterAccountLink = "#",
    facebookPageLink = "#",
    contactNumbers = [],
    emails = [],
    aboutVideoId = "",
    address,
    footerText = "",
    enrollmentProcess = [],
  } = data.attributes;
  return {
    mainHeading,
    mainDescription,
    aboutTitle,
    aboutDescription,
    linkedinPageLink,
    twitterAccountLink,
    facebookPageLink,
    contactNumbers,
    emails,
    address,
    aboutVideoId,
    footerText,
    enrollmentProcess,
  };
};

const transformCategory = (data = []) => {
  const categories = data.map((category = {}) => {
    return {
      id: category?.id,
      name: category.attributes.name,
    };
  });
  return [{ id: 0, name: "All" }, ...categories];
};

const transformCourse = (data = []) => {
  return data.map((course = {}) => {
    const { id, attributes = {} } = course;
    const { category = {} } = attributes;
    const { id: catID, attributes: catAttributes } = category.data;
    const image = attributes?.image?.data?.attributes?.formats?.medium;
    return {
      id,
      ...attributes,
      category: { id: catID, name: catAttributes.name },
      image,
    };
  });
};
