import React, { useCallback, useState } from "react";

export function CourseEnrollInput({
  containerClassName = "",
  inputValue = "",
  blur = false,
  isValid = true,
  type = "text",
  placeholder = "",
  errorMessage = "",
  setValue = () => {},
  setBlur = () => {},
}) {
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onBlur = useCallback(() => {
    if (!blur) {
      setBlur(true);
    }
  }, [blur]);

  return (
    <div className={containerClassName}>
      <div className="contact__form-input">
        <input
          value={inputValue}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
        {!isValid && blur && (
          <div className="contact__form-input-message">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}
