import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import MenuItems from "./MenuItems";
import Topbar from "./Topbar";

import Logo from "../../assets/img/logo/technaedia.png";
import offCanvasLogo from "../../assets/img/logo/footer-logo.png";
import Shape from "../../assets/img/offcanvas/offcanvas-shape-1.jpg";
import { MainContext } from "../../context";
import { SideBar } from "./SideBar";
const Header = (props) => {
  const { headerClass, parentMenu, headerLogo, topbarEnable } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [offCanvasOpen, setOffCanvasOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {topbarEnable ? <Topbar /> : ""}

      <header>
        <div
          id="header-sticky"
          className={`${
            headerClass
              ? headerClass
              : "header__area header__transparent header__space header__plr"
          } ${isVisible ? "header-sticky" : ""}`}
        >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-7 col-md-6">
                <div className="header__logo">
                  <Link to="/">
                    <img src={headerLogo ? headerLogo : Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-7 d-none d-lg-block">
                <div className="header__main-menu text-xxl-end text-center">
                  <nav id="mobile-menu">
                    <ul>
                      <MenuItems parentMenu={parentMenu} />
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-3 col-lg-2 col-5 col-md-6">
                <div className="header__action d-flex justify-content-end">
                  <div className="header__menu-bar">
                    <button
                      onClick={() => {
                        setOffCanvasOpen(true);
                      }}
                      className={menuOpen ? "it-menu-bar open" : "it-menu-bar"}
                    >
                      <i className="far fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <SideBar isOpen={offCanvasOpen} setOpenState={setOffCanvasOpen} />
    </>
  );
};

export default Header;
