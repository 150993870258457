import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";

import { CourseCard, CategoriesList } from "../../components";
import { MainContext } from "../../context";

const CourseSection = () => {
  const [catId, setCatID] = useState(0);
  const { state } = useContext(MainContext);
  const { courses = [] } = state;
  const breakpointColumnsObj = {
    default: 3,
    1200: 3,
    992: 2,
    576: 1,
  };

  const tabStyle =
    "nav nav-pill justify-content-center mb-3 project-2__button-box project-2__button-box-2 masonary-menu";
  const onTabClick = (id) => {
    setCatID(id);
  };
  const filteredCourses = useMemo(
    () =>
      courses.filter((course) => {
        return course.category.id === catId;
      }),
    [catId]
  );
  const renderCourses = () => {
    if (catId === 0 && courses.length > 0) {
      return courses.map((course) => {
        return (
          <div
            key={course.id}
            className="wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <CourseCard course={course} />
          </div>
        );
      });
    }
    if (filteredCourses.length > 0) {
      return filteredCourses.map((course) => {
        return (
          <div
            className="wow animate__fadeInUp"
            data-wow-duration="1.1s"
            key={course.id}
          >
            <CourseCard course={course} />
          </div>
        );
      });
    }
    return null;
  };
  return (
    <div
      className="project-2__area project-2__plr-2 black-bg-2 project-2__space"
      id="courses-section"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="project-2__section-box text-center  pb-30">
              <h4
                className="section-subtitle section-white-bg title-anim wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                Courses That we Offer
              </h4>
              <h3
                className="section-title text-white title-anim wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                We Offer Courses By Best
                <br />
                Instructors in Pakistan.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-12 col-lg-12 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <CategoriesList
              activeTabId={catId}
              tabContainerClass={tabStyle}
              onTabClick={onTabClick}
            />
          </div>
        </div>
        {renderCourses() ? (
          <>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {renderCourses()}
            </Masonry>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link className="border-btn-3" to="/courses">
                View All
              </Link>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="text-white">Oops No Courses Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseSection;
