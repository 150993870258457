import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import MenuItems from "./MenuItems";
import offCanvasLogo from "../../assets/img/logo/technaedia.png";
import Shape from "../../assets/img/offcanvas/offcanvas-shape-1.jpg";
import { MainContext } from "../../context";

export function SideBar({ isOpen = false, setOpenState = () => {} }) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const {
    state: { homePageData },
  } = useContext(MainContext);
  const {
    contactNumbers = [],
    emails = [],
    address = "",
    linkedinPageLink = "#",
    twitterAccountLink = "#",
    facebookPageLink = "#",
  } = homePageData;
  const { email = " " } = emails.length > 0 ? emails[0] : {};
  const { contactNumber = "" } =
    contactNumbers.length > 0 ? contactNumbers[0] : {};
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className="it-offcanvas-area">
        <div className={isOpen ? "itoffcanvas opened" : "itoffcanvas"}>
          <div className="it-offcanva-bottom-shape d-none d-xxl-block">
            <img src={Shape} alt="" />
          </div>
          <div className="itoffcanvas__close-btn">
            <button
              onClick={() => {
                setOpenState(false);
              }}
              className="close-btn"
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="itoffcanvas__logo">
            <Link to="/">
              <img src={offCanvasLogo} alt="" />
            </Link>
          </div>
          <div className="itoffcanvas__text">
            <p>We Offer Best Courses from best Instuctors in Pakistan.</p>
          </div>
          <div
            className={
              innerWidth < 992 ? "mobile-menu mean-container" : "d-none"
            }
          >
            <div className="mean-bar">
              <nav className="mean-nav">
                <ul>
                  <MenuItems />
                </ul>
              </nav>
            </div>
          </div>
          <div className="itoffcanvas__info">
            <h3 className="offcanva-title">Get In Touch</h3>
            <div className="it-info-wrapper mb-20 d-flex align-items-center">
              <div className="itoffcanvas__info-icon">
                <i className="fal fa-envelope"></i>
              </div>
              <div className="itoffcanvas__info-address">
                <span>Email</span>
                <a href={`maito:${email ? email : ""}`}>{email}</a>
              </div>
            </div>
            <div className="it-info-wrapper mb-20 d-flex align-items-center">
              <div className="itoffcanvas__info-icon">
                <i className="fal fa-phone-alt"></i>
              </div>
              <div className="itoffcanvas__info-address">
                <span>Phone</span>
                <a href={`tel:${contactNumber}`}>{contactNumber}</a>
              </div>
            </div>
            <div className="it-info-wrapper mb-20 d-flex align-items-center">
              <div className="itoffcanvas__info-icon">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="itoffcanvas__info-address">
                <span>Location</span>
                <a
                  href={`http://maps.google.com/?q=${address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {address}
                </a>
              </div>
            </div>
          </div>
          <div className="itoffcanvas__social">
            <div className="social-icon">
              <a href={twitterAccountLink} target="_blank" rel="noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href={facebookPageLink} target="_blank" rel="noreferrer">
                <i className="fab fa-facebook-square"></i>
              </a>
              <a href={linkedinPageLink} target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {isOpen ? (
        <div
          className="body-overlay apply"
          onClick={() => {
            setOpenState(false);
          }}
        ></div>
      ) : (
        <div className="body-overlay"></div>
      )}
    </>
  );
}
