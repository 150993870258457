import React, { useCallback, useContext, useState } from "react";
import { TailSpin } from "react-loader-spinner";

import shapeImg1 from "../../assets/img/contact/ct-shape-3.png";
import shapeImg2 from "../../assets/img/contact/ct-shape-4.png";
import {
  CourseEnrollForm,
  SelectCourseInput,
  CourseEnrollInput,
} from "../../components";
import { MainContext } from "../../context";
import { useFormValidation } from "../../hooks";
import {
  emailValidator,
  validator,
  phoneNumberValidator,
} from "../../validators";
const Contact = () => {
  const [courseId, setCourse] = useState(null);
  const name = useFormValidation("", validator, "Name is Required");
  const emailForm = useFormValidation("", emailValidator);
  const contactNumberForm = useFormValidation("", phoneNumberValidator);

  const { state } = useContext(MainContext);
  const { contactNumbers = [], emails = [], address } = state.homePageData;
  const contactNumber = contactNumbers[0]
    ? contactNumbers[0].contactNumber
    : "#";
  const email = emails[0] ? emails[0].email : "#";
  const onCourseChange = useCallback((event) => {
    setCourse(event.target.value);
  }, []);
  const onEnrollSuccess = () => {
    name.reset();
    emailForm.reset();
    contactNumberForm.reset();
    setCourse(null);
  };
  const renderForm = (loading, onEnroll) => {
    const onEnrollClick = onEnroll({
      name: name.state,
      email: emailForm.state,
      contactNumber: contactNumberForm.state,
      courseId,
    });

    const disable =
      !emailForm.isValid ||
      !contactNumberForm.isValid ||
      !name.isValid ||
      loading ||
      !courseId;
    return (
      <>
        <div
          className="contact__form contact__form-3 wow animate__fadeInUp"
          data-wow-duration="1.1s"
        >
          <form>
            <div className="row">
              <CourseEnrollInput
                containerClassName="col-sm-6"
                placeholder="Enter Your Name"
                inputValue={name.state}
                blur={name.blur}
                isValid={name.isValid}
                setValue={name.setState}
                setBlur={name.setBlur}
                errorMessage={name.error}
              />
              <CourseEnrollInput
                containerClassName="col-sm-6"
                type="email"
                placeholder="Enter Your Email"
                inputValue={emailForm.state}
                blur={emailForm.blur}
                isValid={emailForm.isValid}
                setValue={emailForm.setState}
                setBlur={emailForm.setBlur}
                errorMessage={emailForm.error}
              />
              <CourseEnrollInput
                containerClassName="col-sm-12"
                placeholder="Enter Your Number"
                inputValue={contactNumberForm.state}
                blur={contactNumberForm.blur}
                isValid={contactNumberForm.isValid}
                setValue={contactNumberForm.setState}
                setBlur={contactNumberForm.setBlur}
                errorMessage={contactNumberForm.error}
              />
              <div className="col-sm-12">
                <SelectCourseInput onCourseChange={onCourseChange} />
              </div>
            </div>
          </form>
        </div>
        <div
          className="contact__button wow animate__fadeInUp"
          data-wow-duration="1.1s"
        >
          <button
            disabled={disable}
            className="main-btn-sm tp-btn-hover"
            onClick={onEnrollClick}
          >
            {loading ? (
              <TailSpin
                height="30"
                width="30"
                color="#fff"
                ariaLabel="tail-spin-loading"
                radius="5"
                visible
              />
            ) : (
              "Get Enrolled"
            )}
          </button>
        </div>
      </>
    );
  };
  return (
    <div
      className="contact__area contact__plr-2 p-relative  black-bg-2 fix"
      id="contact-section"
    >
      <div className="contact__shape-3 d-none d-xxl-block">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="contact__shape-5 d-none d-xl-block">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="contact__bg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="contact__right-side">
                <div className="contact__section-title pb-10">
                  <h4
                    className="section-subtitle section-white-bg char-anim wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    Contact Us
                  </h4>
                  <h3
                    className="section-title text-white char-anim wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    Get Enrolled in One of Our Courses <br /> and Contact Us
                  </h3>
                </div>
                <div
                  className="contact__text text-color wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <p className="char-anim-2">
                    Get Enrolled With Us and Start Learning Today
                  </p>
                </div>
                <CourseEnrollForm
                  render={renderForm}
                  onSucess={onEnrollSuccess}
                />
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-5 wow animate__fadeInRight"
              data-wow-duration="1.1s"
            >
              <div className="contact__left-side contact__wrapper">
                <div className="contact__item-box contact__box-2">
                  <div
                    className="contact__item d-flex align-items-center wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact__icon contact__icon-color-1">
                      <span>
                        <a href={`tel:${contactNumber}`}>
                          <i className="fal fa-phone-alt"></i>
                        </a>
                      </span>
                    </div>
                    <div className="contact__content">
                      <h4 className="contact__title-sm title-color-1">
                        Call Us Now
                      </h4>
                      <span className="text-white">
                        {contactNumbers.map(({ id, contactNumber }) => {
                          return (
                            <div key={id}>
                              <a href={`tel:$${contactNumber}`}>
                                {contactNumber}
                              </a>
                            </div>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                  <div
                    className="contact__item d-flex align-items-center wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact__icon contact__icon-color-2">
                      <span>
                        <a href={`mailto:${email}`}>
                          <i className="fal fa-envelope-open-text"></i>
                        </a>
                      </span>
                    </div>
                    <div className="contact__content">
                      <h4 className="contact__title-sm title-color-2">
                        Email Us
                      </h4>
                      <span className="text-white">
                        {emails.map(({ id, email }) => {
                          return (
                            <div key={id}>
                              <a href={`mailto:${email}`}>{email}</a>
                            </div>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                  <div
                    className="contact__item d-flex align-items-center wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact__icon contact__icon-color-3">
                      <span>
                        <i className="fal fa-map-marker-check"></i>
                      </span>
                    </div>
                    <div className="contact__content">
                      <h4 className="contact__title-sm title-color-3">
                        Your location
                      </h4>
                      <span className="text-white">
                        <a
                          href={`http://maps.google.com/?q=${address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {address}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
