import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../../context";

const Work = () => {
  const { state } = useContext(MainContext);
  const { enrollmentProcess = [] } = state.homePageData;
  const isValid = enrollmentProcess.length > 0;
  const renderStep = () => {};
  return (
    <div className="work-3__area work-3__plr work-2__space black-bg-2">
      <div className="container-fluid work-3__bg">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="work-3__left-side">
              <div className="work-3__section-box">
                <h4
                  className="section-subtitle section-white-bg char-anim wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  Our Enrollment Process
                </h4>
                <h3
                  className="section-title text-white char-anim wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  We will offer Best Courses
                </h3>
              </div>
              <div
                className="work-3__text wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                <p className="char-anim-2">
                  we Offer best services from best Instructors
                </p>
              </div>
              <div
                className="work-3__button wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                <p>Discover our courses and get Enrolled</p>
                <Link
                  className="main-btn tp-btn-hover alt-color-white"
                  to="/courses"
                >
                  <span>View All Courses</span>
                  <b></b>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration="1.1s"
          >
            <div className="work-3__wrapper-box">
              <div className="work-3__wrapper work-3__item-1 mb-30">
                <div className="work-3__item">
                  <div className="work-3__content d-flex align-items-center mb-15">
                    <div className="work-3__icon icon-color-1">
                      <span>
                        <i className="fas fa-lightbulb-on"></i>
                      </span>
                    </div>
                    <div className="work-3__title">
                      <h4 className="work-3__section-title title-color-1">
                        <Link to="#">
                          {isValid && enrollmentProcess[0].stepTitle}
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="work-3__right-text">
                    <p>{isValid && enrollmentProcess[0].stepDescription}</p>
                  </div>
                </div>
              </div>
              <div className="work-3__wrapper work-3__item-2 mb-30">
                <div className="work-3__item">
                  <div className="work-3__content d-flex align-items-center mb-15">
                    <div className="work-3__icon icon-color-2">
                      <span>
                        <i className="fas fa-cogs"></i>
                      </span>
                    </div>
                    <div className="work-3__title">
                      <h4 className="work-3__section-title title-color-2">
                        <Link to="#">
                          {isValid && enrollmentProcess[1].stepTitle}
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="work-3__right-text">
                    <p>{isValid && enrollmentProcess[1].stepDescription}</p>
                  </div>
                </div>
              </div>
              <div className="work-3__wrapper work-3__item-3 mb-30">
                <div className="work-3__item">
                  <div className="work-3__content d-flex align-items-center mb-15">
                    <div className="work-3__icon icon-color-3">
                      <span>
                        <i className="fas fa-chart-bar"></i>
                      </span>
                    </div>
                    <div className="work-3__title">
                      <h4 className="work-3__section-title title-color-3">
                        <Link to="/project-details">
                          {isValid && enrollmentProcess[2].stepTitle}
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="work-3__right-text">
                    <p>{isValid && enrollmentProcess[2].stepDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
