import { useContext } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo/technaedia.png";
import footerBG from "../../assets/img/footer/footer-bg.jpg";
import { MainContext } from "../../context";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  const year = new Date().getFullYear() || "";
  const { state } = useContext(MainContext);
  const {
    linkedinPageLink,
    twitterAccountLink,
    facebookPageLink,
    contactNumbers,
    footerText,
  } = state.homePageData;
  const contactNumber =
    contactNumbers && contactNumbers[0] ? contactNumbers[0].contactNumber : "#";
  return (
    <footer>
      <div
        className={footerClass ? footerClass : "footer__area footer__plr"}
        style={{ backgroundImage: `url(${footerBG})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-1">
                <div className="footer__logo">
                  <Link to="/">
                    <img src={footerLogo ? footerLogo : Logo} alt="" />
                  </Link>
                </div>
                <div className="footer__content">
                  <div className="footer__text">
                    <p>{footerText}</p>
                  </div>
                  <div className="footer__number-wrapper d-flex align-items-center">
                    <div className="footer__number-icon">
                      <span>
                        <i className="fal fa-phone-alt"></i>
                      </span>
                    </div>
                    <div className="footer__number-info">
                      <span>Have Any Questions?</span>
                      <a href={`tel:${contactNumber}`}>{contactNumber}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-3 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp" />

            {/* <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-2">
                <h4 className="footer__title">
                  <Link to="/service">Service</Link>
                </h4>
                <div className="footer__menu-list">
                  <ul>
                    <li>
                      <a href="#">Web Design</a>
                    </li>
                    <li>
                      <a href="#">Development</a>
                    </li>
                    <li>
                      <a href="#">WordPress</a>
                    </li>
                    <li>
                      <a href="#">Online Marketing</a>
                    </li>
                    <li>
                      <a href="#">Content</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-3">
                <h4 className="footer__title">
                  <a href="#">Support Link</a>
                </h4>
                <div className="footer__menu-list">
                  <ul>
                    <li>
                      <a href="#">Web Design</a>
                    </li>
                    <li>
                      <a href="#">Development</a>
                    </li>
                    <li>
                      <a href="#">WordPress</a>
                    </li>
                    <li>
                      <a href="#">Online Marketing</a>
                    </li>
                    <li>
                      <a href="#">Content</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-4">
                {/* <h4 className="footer__title">
                  <a href="#">Newslatter</a>
                </h4>
                <div className="footer__content">
                  <p>
                    Our approach to itis unique around know work an we know.
                  </p>
                </div>
                <div className="footer__input">
                  <form action="#">
                    <input type="email" placeholder="Your email" />
                    <button className="subscribe-btn">Subscribe </button>
                  </form>
                </div> */}
                <div className="footer__social-box">
                  <h4 className="footer__title">Social Media</h4>

                  <a href={linkedinPageLink} target="_blank" rel="noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a href={facebookPageLink} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href={twitterAccountLink} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright__area copyright__plr black-bg-3">
        <div className="container-fluid">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="wow animate__slideInUp" data-wow-duration="1.1s">
              <div className="copyright__text">
                <span>Copyright Technaedia {year}, All Right Reserved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
