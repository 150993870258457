import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../../components/Breadcrumb";
import { CoursesContext } from "../../context";
import { baseURL, clientURL } from "../../api";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const CourseMainBody = () => {
  let tab1 = "Course Details",
    tab2 = "Course Content";

  const tabStyle = "nav nav-tabs pro-details-nav-btn";
  const { state } = useContext(CoursesContext);
  const { courseData } = state;
  const {
    id,
    title = "",
    description = "",
    courseLevel = "",
    coursePrice,
    courseOffers = [],
    courseRequirements = [],
    courseContents = [],
    image = {},
    totalLectures,
    courseDuration = 1,
  } = courseData;
  return (
    <main>
      <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`/courses/${id}`} />
          <meta property="og:title" content={title} />
          <meta
            property="og:description"
            content={`${title} from Technaedia Courses`}
          />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`${clientURL}/courses/${id}`} />
          <meta property="og:image" content={`${baseURL}${image.url}`} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Breadcrumb pageTitle="Course Details" />
        <div className="product-details__area product-details__plr mt-100 mb-70">
          <div className="container">
            <div className="row gx-40 align-items-center mb-100">
              <div
                className="col-xl-6 col-lg-6 col-md-6 wow animate__fadeInLeft"
                data-wow-duration="1.1s"
              >
                <div className="product-details__main-img pb-20">
                  <img src={image && `${baseURL}${image.url}`} alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="product-details__left-side">
                  <h4
                    className="product-details__left-title wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    {title}
                  </h4>
                  <h5
                    className=" wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <span>{courseLevel}</span> / <span>Rs {coursePrice}</span>
                  </h5>
                  {/* <div
                    className="product-details__star wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star color"></i>
                    <i className="fas fa-star color"></i>
                    <span>(03)</span>
                  </div> */}
                  <p className="wow animate__fadeInUp" data-wow-duration="1.1s">
                    <span>Course Duration : {courseDuration} hrs</span>
                  </p>
                  <p className="wow animate__fadeInUp" data-wow-duration="1.1s">
                    <span>Total Lectures : {totalLectures}</span>
                  </p>
                  <Link
                    className="yellow-btn tp-btn-hover alt-color mt-20 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                    to="/?viewID=contact"
                  >
                    <span>Get Enrolled</span>
                  </Link>
                  <div
                    className="product-details__social-box mt-35 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                    style={{ display: "flex", gap: 5, alignItems: "center" }}
                  >
                    <span>Share:</span>

                    <FacebookShareButton
                      url={`${clientURL}/courses/${id}`}
                      quote={title}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`${clientURL}/courses/${id}`}
                      title={title}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="productdetails-tabs mb-100">
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12 col-12">
                    <Tabs>
                      <div className="product-additional-tab">
                        <div className="pro-details-nav mb-40">
                          <TabList className={tabStyle}>
                            <Tab>
                              <button className="nav-links">
                                <span>{tab1}</span>
                              </button>
                            </Tab>
                            <Tab>
                              <button className="nav-links">
                                <span>{tab2}</span>
                              </button>
                            </Tab>
                          </TabList>
                        </div>
                        <div
                          className="tab-content tp-content-tab"
                          id="myTabContent-2"
                        >
                          <TabPanel>
                            <p className="mb-30">{description}</p>
                            <div
                              className="course-details__list pb-30 wow animate__fadeInUp"
                              data-wow-duration="1.1s"
                            >
                              {courseOffers.length > 0 && (
                                <>
                                  <h6 className="course-details__info-heading">
                                    What Course Offers :
                                  </h6>

                                  <ul>
                                    {courseOffers.map((offer = {}) => {
                                      return (
                                        <li key={offer.id}>
                                          {offer.description}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              )}

                              {courseRequirements.length > 0 && (
                                <>
                                  <h6 className="course-details__info-heading">
                                    Course Requirements
                                  </h6>
                                  <ul>
                                    {courseRequirements.map(
                                      (requirement = {}) => {
                                        return (
                                          <li key={requirement?.id}>
                                            {requirement?.requirement}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="product__details-info table-responsive">
                              <table className="table table-striped">
                                <tbody>
                                  {courseContents.length > 0 &&
                                    courseContents.map(
                                      (
                                        courseContent = {
                                          id: 1,
                                          description: "",
                                        }
                                      ) => {
                                        return (
                                          <tr key={courseContent.id}>
                                            <td className="add-info">
                                              {courseContent.id} .
                                            </td>
                                            <td className="add-info-list">
                                              {courseContent.contentDescription}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </TabPanel>
                          {/* <TabPanel>
                            <div className="product-details-review">
                              <h3 className="tp-comments-title mb-35">
                                3 reviews for “Wide Cotton Tunic extreme hammer”
                              </h3>
                              <div className="latest-comments mb-55">
                                <ul>
                                  <li>
                                    <div className="comments-box d-flex">
                                      <div className="comments-text">
                                        <div className="comments-top d-sm-flex align-items-start justify-content-between mb-5">
                                          <div className="avatar-name">
                                            <b>Siarhei Dzenisenka</b>
                                            <div className="comments-date mb-20">
                                              <span>
                                                March 27, 2018 9:51 am
                                              </span>
                                            </div>
                                          </div>
                                          <div className="user-rating">
                                            <ul>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fal fa-star"></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <p className="m-0">
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit. Curabitur
                                          vulputate vestibulum Phasellus
                                          rhoncus, dolor eget viverra pretium,
                                          dolor tellus aliquet nunc, vitae
                                          ultricies erat elit eu lacus.
                                          Vestibulum non justo consectetur,
                                          cursus ante, tincidunt sapien. Nulla
                                          quis diam sit amet turpis interdum
                                          accumsan quis nec enim. Vivamus
                                          faucibus ex sed nibh egestas
                                          elementum. Mauris et bibendum dui.
                                          Aenean consequat pulvinar luctus.
                                          Suspendisse consectetur tristique
                                          tortor
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="comments-box d-flex">
                                      <div className="comments-text">
                                        <div className="comments-top d-sm-flex align-items-start justify-content-between mb-5">
                                          <div className="avatar-name">
                                            <b>Tommy Jarvis </b>
                                            <div className="comments-date mb-20">
                                              <span>
                                                March 27, 2018 9:51 am
                                              </span>
                                            </div>
                                          </div>
                                          <div className="user-rating">
                                            <ul>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fal fa-star"></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <p className="m-0">
                                          We have covered many special events
                                          such as fireworks, fairs, parades,
                                          races, walks, awards ceremonies,
                                          fashion shows, sporting events, and
                                          even a memorial service. Lorem ipsum
                                          dolor sit amet, consectetur adipiscing
                                          elit. Curabitur vulputate vestibulum
                                          Phasellus rhoncus, dolor eget viverra
                                          pretium, dolor tellus aliquet nunc,
                                          vitae ultricies erat elit eu lacus.
                                          Vestibulum non justo consectetur,
                                          cursus ante, tincidunt sapien. Nulla
                                          quis diam sit amet turpis interdum
                                          accumsan quis nec enim. Vivamus
                                          faucibus ex sed nibh egestas
                                          elementum. Mauris et bibendum dui.
                                          Aenean consequat pulvinar luctus.
                                          Suspendisse consectetur
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="comments-box d-flex">
                                      <div className="comments-text">
                                        <div className="comments-top d-sm-flex align-items-start justify-content-between mb-5">
                                          <div className="avatar-name">
                                            <b>Johnny Cash</b>
                                            <div className="comments-date mb-20">
                                              <span>
                                                March 27, 2018 9:51 am
                                              </span>
                                            </div>
                                          </div>
                                          <div className="user-rating">
                                            <ul>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fas fa-star"></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a href="#">
                                                  <i className="fal fa-star"></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <p className="m-0">
                                          This is cardigan is a comfortable warm
                                          classic piece. Great to layer with a
                                          light top and you can dress up or down
                                          given the jewel buttons. I'm 5'8”
                                          128lbs a 34A and the Small fit fine.
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="product-details-comment pb-100">
                                <div className="comment-title mb-20">
                                  <h3>Add a review</h3>
                                  <p>
                                    Your email address will not be published.
                                    Required fields are marked*
                                  </p>
                                </div>
                                <div className="comment-rating mb-20 d-flex">
                                  <span>Overall ratings</span>
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fal fa-star"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="comment-input-box">
                                  <form action="#">
                                    <div className="row">
                                      <div className="col-xxl-12">
                                        <div className="comment-input">
                                          <textarea placeholder="Your review..."></textarea>
                                        </div>
                                      </div>
                                      <div className="col-xxl-6">
                                        <div className="comment-input">
                                          <input
                                            type="text"
                                            placeholder="Your Name*"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xxl-6">
                                        <div className="comment-input">
                                          <input
                                            type="email"
                                            placeholder="Your Email*"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xxl-12">
                                        <div className="comment-submit">
                                          <button
                                            type="submit"
                                            className="it-btn"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <FaqTwo />
                          </TabPanel> */}
                        </div>
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
         <div className="col-xl-12">
           <div className="product__title-sm wow animate__fadeInUp" data-wow-duration="1.1s">
            <h4>Showing all All Products:</h4>
           </div>
         </div>
        </div>
        <div className="row">
         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
          <SingleProduct
								Image={productImg1}
							/>
         </div>
         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
          <SingleProduct
								Image={productImg2}
							/>
         </div>
         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
          <SingleProduct
								Image={productImg3}
							/>
         </div>
         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
          <SingleProduct
								Image={productImg4}
							/>
         </div>
         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
          <SingleProduct
								Image={productImg5}
							/>
         </div>
         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
          <SingleProduct
								Image={productImg6}
							/>
         </div>
        </div>
         */}
          </div>
        </div>

        {/* <CTA
      ctaBG={bgImg}
     /> */}
      </>
    </main>
  );
};

export default CourseMainBody;
