import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import MenuItems from "./MenuItems";

import Logo from "../../assets/img/logo/technaedia.png";
import { SideBar } from "./SideBar";
const HeaderStyleTwo = (props) => {
  const { headerClass, parentMenu } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [offCanvasOpen, setOffCanvasOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const [home, setHome] = useState(false);
  const [pages, setPages] = useState(false);

  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <header>
        <div
          className={`${
            headerClass
              ? headerClass
              : "header__area header__border header-five header__transparent header__plr"
          } ${isVisible ? "header-sticky" : ""}`}
        >
          <div
            className={
              isVisible ? "menu-bar menu-sticky sticky" : "menu-bar menu-sticky"
            }
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xxxl-2 col-xxl-3 col-xl-3 col-lg-3 col-7 col-md-6">
                  <div className="header__logo header__logo-border text-start text-lg-center">
                    <Link to="/">
                      <img src={Logo} alt="Technaedia Logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-xxxl-6 col-xxl-6 col-xl-6 col-lg-7 d-none d-lg-block">
                  <div className="header__main-menu header__white-menu text-center">
                    <nav id="mobile-menu">
                      <ul>
                        <MenuItems parentMenu={parentMenu} />
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xxxl-4 col-xxl-3 col-xl-3 col-lg-2 col-5 col-md-6">
                  <div className="header__action header__action-border d-flex justify-content-end align-items-center">
                    <div className="header__menu-bar">
                      <button
                        onClick={() => {
                          setOffCanvasOpen(true);
                        }}
                        className={
                          menuOpen
                            ? "it-menu-bar bars-color open"
                            : "it-menu-bar bars-color"
                        }
                      >
                        <i className="far fa-bars"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <SideBar isOpen={offCanvasOpen} setOpenState={setOffCanvasOpen} />
    </>
  );
};

export default HeaderStyleTwo;
