import React, { useCallback, useContext, useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import Breadcrumb from "../../components/Breadcrumb";
import { CourseCard, CategoriesList, Preloader } from "../../components";
import { CoursesContext, MainContext } from "../../context";

const CoursesGrid = () => {
  const [catId, setCatID] = useState(0);
  const breakpointColumnsObj = {
    default: 3,
    1200: 3,
    992: 2,
    576: 1,
  };
  const { state, getCourses } = useContext(CoursesContext);
  const { state: mainState } = useContext(MainContext);
  const { loading, courses } = state;
  const { categoriesLoading } = mainState;
  useEffect(() => {
    getCourses(catId);
  }, [catId]);
  const tabStyle =
    "nav justify-content-center project-2__button-box masonary-menu";
  const onTabClick = useCallback((categoryId) => {
    setCatID(categoryId);
  }, []);

  return (
    <main>
      {loading || categoriesLoading ? <Preloader /> : ""}
      <Breadcrumb pageTitle="Courses" />
      <div className="project-2__area project-2__plr-3 project-2__space p-relative fix">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <CategoriesList
                activeTabId={catId}
                tabContainerClass={tabStyle}
                onTabClick={onTabClick}
              />
            </div>
          </div>
          {courses.length > 0 ? (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {courses.map((course) => {
                return (
                  <div key={course.id}>
                    <CourseCard course={course} />
                  </div>
                );
              })}
            </Masonry>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Oops No Courses Found</p>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default CoursesGrid;
