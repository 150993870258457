import React from "react";
import CoursesGrid from "./CoursesGrid";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet-async";

export const CoursesPage = () => {
  return (
    <>
      <Helmet>
        <title>All Courses</title>
        <link rel="canonical" href="/courses" />
      </Helmet>
      <Header
        parentMenu="page"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <CoursesGrid />
          <Footer />
        </div>
      </div>
    </>
  );
};
