import React from "react";
import { createRoot } from "react-dom/client";

import SwiperCore, { Navigation, Pagination } from "swiper";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { MainProvider, CoursesProvider } from "./context";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

createRoot(document.getElementById("root")).render(
  <MainProvider>
    <CoursesProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CoursesProvider>
  </MainProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
