import { useState, useEffect } from "react";

export const useFormValidation = (
  initialValue = "",
  validator = () => {},
  errorMessage = ""
) => {
  const [state, setState] = useState(initialValue);
  const [blur, setBlur] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");
  const reset = () => {
    setState(initialValue);
    setBlur(false);
    setIsValid(false);
    setError("");
  };
  useEffect(() => {
    if (validator) {
      validator(state, setError, setIsValid, errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blur, state]);

  return {
    state,
    blur,
    setState,
    setBlur,
    setError,
    error,
    isValid,
    reset,
  };
};
