import api from "../api";
import { getError } from "../utils";
import useDataContext from "./useDataContext";
const courses = "courses-context";
const courseContextTypes = {
  setLoading: `${courses}/setLoading`,
  setError: `${courses}/setError`,
  setCourses: `${courses}/setCoursesList`,
  setCourseLoading: `${courses}/setCourseLoading`,
  setCourseError: `${courses}/setCoursesError`,
  setCourseData: `${courses}/setCourseData`,
};

const INIT_STATE = {
  loading: true,
  error: "",
  courses: [],
  courseLoading: true,
  courseError: "",
  courseData: null,
};

const reducer = (state, action = {}) => {
  switch (action.type) {
    case courseContextTypes.setLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case courseContextTypes.setError:
      return {
        ...state,
        error: action.payload,
      };
    case courseContextTypes.setCourses:
      return {
        ...state,
        courses: action.payload,
      };
    case courseContextTypes.setCourseLoading:
      return {
        ...state,
        courseLoading: action.payload,
      };
    case courseContextTypes.setCourseData:
      return {
        ...state,
        courseData: action.payload,
      };
    case courseContextTypes.setCourseError:
      return {
        ...state,
        courseError: action.payload,
      };
    default:
      return state;
  }
};

// Actions
const getCourses = (dispatch) => {
  return async (catID = 0) => {
    try {
      const params = {
        populate: [`category`, "image"],
        fields: ["title", "courseLevel", "coursePrice", "startsFrom"],
        pagination: {
          pageSize: 10,
          page: 1,
        },
        sort: ["createdAt:desc"],
      };
      if (catID !== 0) {
        params.filters = {
          category: {
            id: {
              $eq: catID,
            },
          },
        };
      }
      const response = await api.get("/courses", {
        params,
      });
      const payload = transformCourses(response.data);
      dispatch({
        type: courseContextTypes.setCourses,
        payload,
      });
    } catch (err) {
      const message = getError(err);
      console.log("GetCourses", message);
      dispatch({ type: courseContextTypes.setError, payload: message });
    } finally {
      dispatch({ type: courseContextTypes.setLoading, payload: false });
    }
  };
};
const getCourse = (dispatch) => {
  return async (courseID) => {
    try {
      dispatch({ type: courseContextTypes.setCourseLoading, payload: true });
      const response = await api.get(`/courses/${courseID}?populate=*`);
      const payload = transformCourse(response.data);
      dispatch({
        type: courseContextTypes.setCourseData,
        payload,
      });
    } catch (err) {
      const message = getError(err);
      console.log("GetCourses", message);
      dispatch({ type: courseContextTypes.setCourseError, payload: message });
    } finally {
      dispatch({ type: courseContextTypes.setCourseLoading, payload: false });
    }
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const [CoursesContext, CoursesProvider] = useDataContext(
  INIT_STATE,
  reducer,
  {
    getCourses,
    getCourse,
  }
);

// Transformers

const transformCourses = (data = []) => {
  return data.map(transformCourse);
};

const transformCourse = (course = {}) => {
  const { id, attributes = {} } = course;
  const { category = {} } = attributes;
  const { id: catID, attributes: catAttributes } = category.data;
  const image = attributes?.image?.data?.attributes?.formats?.medium;
  return {
    id,
    ...attributes,
    category: { id: catID, name: catAttributes.name },
    image,
  };
};
