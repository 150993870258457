import { Link } from "react-router-dom";
import { baseURL } from "../../api";
export function MenuItem({
  label,
  htmlId,
  yOffset = 10,
  operation = "add",
  isLink = false,
}) {
  const onItemClick = () => {
    if (htmlId) {
      const el = document.getElementById(`${htmlId}-section`);

      if (el) {
        let y = el.getBoundingClientRect().top + window.pageYOffset;
        y = operation === "sub" ? y - yOffset : y + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  return (
    <li className={`has-dropdown active`}>
      {isLink ? (
        <Link to={`/?viewID=${htmlId}`}>{label}</Link>
      ) : (
        <div className="menu-item" onClick={onItemClick}>
          {label}
        </div>
      )}
    </li>
  );
}
