import React from "react";

export const Preloader = () => {
  return (
    <div id="preloader">
      <div className="preloader">
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
