import React from "react";
import { useLocation } from "react-router-dom";
import { MenuItem } from "./MenuItem";
const MenuItems = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === "/";

  return (
    <>
      <MenuItem isLink={!isHomePage} label="Home" htmlId="home" />
      <MenuItem
        isLink={!isHomePage}
        label="About"
        htmlId="about"
        operation="sub"
        yOffset={100}
      />
      <MenuItem isLink={!isHomePage} label="Courses" htmlId="courses" />
      <MenuItem
        htmlId="contact"
        label="Contact"
        operation="sub"
        yOffset={100}
        isLink={!isHomePage}
      />
    </>
  );
};

export default MenuItems;
