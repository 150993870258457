import axios from "axios";

const isProductionEnv = process.env.NODE_ENV === "production";
export const baseURL = isProductionEnv
  ? "https://admin.dev.university.techneadia.com"
  : "http://localhost:1337";
export const clientURL = isProductionEnv
  ? "https://dev.university.techneadia.com"
  : "http://localhost:3000";
const instance = axios.create({
  baseURL: `${baseURL}/api`,
});

instance.interceptors.response.use((response) => {
  const { data, meta } = response.data;
  response.data = data;
  response.meta = meta;
  return response;
});

export default instance;
