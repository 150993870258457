import React, { useContext } from "react";
import { MainContext } from "../../context";

export function CategoriesList({
  activeTabId = 0,
  onTabClick = () => {},
  tabContainerClass,
}) {
  const { state } = useContext(MainContext);
  const { categories = [] } = state;
  return (
    <div className={tabContainerClass}>
      {categories.map((category) => (
        <div
          className={
            activeTabId === category.id ? "react-tabs__tab--selected" : ""
          }
          key={category.id}
        >
          <button onClick={() => onTabClick(category.id)}>
            {category.name}
          </button>
        </div>
      ))}
    </div>
  );
}
