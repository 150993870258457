import React, { useEffect } from "react";

// import BrandTwo from "../../components/Brand/BrandTwo";
// import TestimonialPart from "./TestimonialSection";
import About from "./AboutSection";
// import Blog from "./BlogSection";
import Contact from "./ContactSection";
// import Counter from "./CounterSection";
import Banner from "./Banner";
// import Newsletter from "./NewsletterSection";
import CoursesList from "./CourseSection";
// import Service from "./ServiceSection";
import Work from "./WorkSection";
import { useQuery } from "../../hooks";

const HomeMain = () => {
  const query = useQuery();
  const viewID = query.get("viewID");
  useEffect(() => {
    if (viewID) {
      const element = document.getElementById(`${viewID}-section`);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [viewID]);
  return (
    <main>
      <Banner />

      {/* <BrandTwo itemClass="brand-3__area brand-3__plr brand-3__space black-bg-2" /> */}

      <About />

      {/* <Service /> */}

      {/* <Counter /> */}

      <CoursesList />

      {/* <TestimonialPart /> */}

      <Work />

      <Contact />

      {/* <Blog /> */}

      {/* <Newsletter /> */}
    </main>
  );
};

export default HomeMain;
