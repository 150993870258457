import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import WOW from "wowjs";

//Custom Components

import HomeFive from "./pages/home-5";
import { CoursesPage, CourseDetail } from "./pages";
import Error from "./pages/error";
import ProductDetails from "./pages";

// import About from "./pages/about";
// import AboutMe from "./pages/about/about-me";
// import Service from "./pages/service";
// import ServiceTwo from "./pages/service/service-2";
// import ServiceThree from "./pages/service/service-3";
// import ServiceDetails from "./pages/service/service-details";
// import Project from "./pages/project";
// import ProjectOne from "./pages/project/project-1";
// import ProjectTwo from "./pages/project/project-2";
// import ProjectDetails from "./pages/project/project-details";
// import Cart from "./pages/cart";
// import Checkout from "./pages/checkout";
// import Faq from "./pages/faq";
// import Price from "./pages/price";
// import Testimonial from "./pages/testimonial";
// import Login from "./pages/login";
// import Register from "./pages/register";
// import Job from "./pages/job";
// import JobDetails from "./pages/job/job-details";
// import Product from "./pages/product";
// import Blog from "./pages/blog";
// import BlogDetails from "./pages/blog/blog-details";
// import Contact from "./pages/contact";

import { ScrollToTop } from "./components";
import LoadTop from "./components/ScrollTop/LoadTop";
import { MainContext } from "./context";

const App = () => {
  const { state, getCategories } = useContext(MainContext);
  const { categoriesLoading } = state;
  function handleRouteChange() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  useEffect(() => {
    handleRouteChange();
    if (categoriesLoading) {
      getCategories();
    }
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <>
          <ScrollToTop />
          <LoadTop />
          <Routes>
            <Route path="/" exact element={<HomeFive />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/courses/:id" element={<CourseDetail />} />
            <Route path="*" element={<Error />} />

            {/* <Route path="/about-me" element={<AboutMe />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/service-1" element={<Service />} />
          <Route path="/service-2" element={<ServiceTwo />} />
          <Route path="/service-3" element={<ServiceThree />} />
          <Route path="/service-details" element={<ServiceDetails />} />
          <Route path="/project" element={<Project />} />
          <Route path="/project-1" element={<ProjectOne />} />
          <Route path="/project-2" element={<ProjectTwo />} />
          <Route path="/project-details" element={<ProjectDetails />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/job" element={<Job />} />
          <Route path="/job-details" element={<JobDetails />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/price" element={<Price />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} /> */}
          </Routes>
        </>
      </div>
    </HelmetProvider>
  );
};

export default App;
