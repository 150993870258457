import React, { useContext, useEffect } from "react";
import CourseMainBody from "./Main";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useParams } from "react-router";
import { CoursesContext } from "../../context";
import { Preloader } from "../../components";

export const CourseDetail = () => {
  const id = useParams()?.id;
  const { getCourse, state } = useContext(CoursesContext);
  const { courseLoading } = state;

  useEffect(() => {
    if (id) {
      getCourse(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      {courseLoading ? (
        <Preloader />
      ) : (
        <>
          <Header
            parentMenu="page"
            headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
          />
          <div id="smooth-wrapper">
            <div id="smooth-content">
              <CourseMainBody />
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CourseDetail;
