export const validator = (value, setError, setIsValid, errorMessage) => {
  if (value.trim() === "") {
    setIsValid(false);
    return setError(errorMessage);
  }
  setIsValid(true);
  return setError("");
};

export const phoneNumberValidator = (value, setError, setIsValid) => {
  const regex = /^[0+][1-9]{1}[0-9]{3,14}$/;
  if (value.trim().length === 0) {
    setIsValid(false);
    setError("Phone Number is Required");
  } else if (!regex.test(value)) {
    setIsValid(false);
    setError("Please Enter Valid Phone Number");
  } else {
    setIsValid(true);
    setError("");
  }
};
