import React, { useCallback, useState } from "react";
import api from "../../api";
import { Modal } from "../../components";
export function CourseEnrollForm({ render = () => {}, onSucess = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const onEnrollClick = ({
    name = "",
    email = "",
    contactNumber = "",
    courseId,
  }) => {
    return async (e) => {
      try {
        // e.preventDefault();
        setLoading(true);
        if (name && email && contactNumber && courseId) {
          await api.post("/students", {
            data: { name, email, contactNumber, courses: courseId },
          });
          setOpen(true);
          onSucess();
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };
  };
  const onModalDissmiss = useCallback(() => {
    setOpen(false);
    if (error) {
      setError("");
    }
  }, [error]);

  return (
    <>
      {open && (
        <Modal
          header="Thanks for Enrolling in Our Course"
          body={
            error
              ? "Oops Something goes wrong"
              : "Our Team will contact you as soon as possible."
          }
          onDismiss={onModalDissmiss}
        />
      )}

      {render(loading, onEnrollClick)}
    </>
  );
}
