import React from "react";
import ReactDOM from "react-dom";

export const Modal = ({ header, body, onDismiss = () => {}, actions }) => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: "rgba(0,0,0,0.8)",
        overflow: "hidden",
        zIndex: 99999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        onDismiss();
      }}
    >
      <div
        style={{
          padding: 15,
          backgroundColor: "#fff",
          borderRadius: 10,
        }}
        role="document"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            padding: 10,
            borderBottom: "0.5px solid rgba(0,0,0,0.1)",
          }}
        >
          <h5
            style={{
              fontSize: 18,
              fontWeight: "500",
              color: "#3cb371",
            }}
          >
            {header}
          </h5>
        </div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>{body}</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={onDismiss}
          >
            Close
          </button>
        </div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;
