export const emailValidator = (value, setError, setIsValid) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.trim() === "") {
    setIsValid(false);
    return setError("Email is required");
  } else if (!regex.test(value)) {
    setIsValid(false);
    return setError("Please Enter Valid Email");
  }
  setIsValid(true);
  return setError("");
};
