import { useEffect, useState } from "react";
import { getError } from "../../utils";
import api from "../../api";

const option = { id: 0, title: "Select Your Course" };

export function SelectCourseInput({ onCourseChange = () => {} }) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCourses = async () => {
    try {
      const response = await api.get("/courses", {
        params: {
          fields: ["title"],
          pagination: {
            pageSize: 1000,
            page: 1,
          },
          sort: ["createdAt:desc"],
        },
      });
      const courses = response.data.map((course) => ({
        id: course.id,
        title: course.attributes.title,
      }));
      setCourses([option, ...courses]);
    } catch (err) {
      console.log(getError(err));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loading) {
      getCourses();
    }
  }, []);
  return (
    <div className="contact__form-input">
      <select disabled={loading} onChange={onCourseChange}>
        {courses.map((course = {}) => {
          return (
            <option key={course.id} value={course.id}>
              {course.title}
            </option>
          );
        })}
      </select>
    </div>
  );
}
