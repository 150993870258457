import React, { useContext, useEffect } from "react";
import HomeMain from "./HomeMain";
import Footer from "../../components/Footer";
import HeaderStyleTwo from "../../components/Header/HeaderStyleTwo";
import { MainContext } from "../../context";
import { Preloader } from "../../components";

const Home = () => {
  const { state, getHomePage, getCourses } = useContext(MainContext);
  const { loading, categoriesLoading, coursesLoading } = state;
  const showLoader = loading || categoriesLoading || coursesLoading;
  useEffect(() => {
    getHomePage();
    getCourses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {showLoader ? (
        <Preloader />
      ) : (
        <>
          <HeaderStyleTwo parentMenu="home" />
          <div id="smooth-wrapper">
            <div id="smooth-content">
              <HomeMain />
              <Footer footerClass="footer__area footer__plr footer__plr-3" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
